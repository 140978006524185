<template>
  <div>
    <NavigationTopBar />

    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Landing'
}
</script>

<style lang="sass">
@import "@/assets/vars.sass"
</style>
